import { graphql } from "gatsby"
import React from "react"
import Page from "../../templates/page"

interface Props {
  data: any
}

export default function NamedClothing(props: Props) {
  const page = props.data.allContentfulPage.edges[0].node
  return <Page page={page} />
}

export const pageQuery = graphql`
  query PageQuery {
    allContentfulPage(
      filter: { contentful_id: { eq: "2RZPYZkvoH6bncsRYHE11Q" } }
    ) {
      edges {
        node {
          title
          mainContent {
            json
          }
        }
      }
    }

    allContentfulAsset {
      edges {
        node {
          id
          file {
            url
          }
        }
      }
    }
  }
`
