import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import CustomHelmet from "../components/custom-helmet"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { BLOCKS } from "@contentful/rich-text-types"

import "./page.scss"

interface Props {
  page: any
}

export default function Page(props: Props) {
  const page = props.page

  if (!page) return null

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { file } = node.data.target.fields
        const { url } = file["en-US"]
        return <img src={url} />
      },
    },
  }

  return (
    <>
      <CustomHelmet title={page.title} />

      <Header theme="black" />

      <div className="page">
        <h1 className="page__title">{page.title}</h1>
        {page.mainContent && (
          <div className="page__main-content">
            {documentToReactComponents(page.mainContent.json, options)}
          </div>
        )}
      </div>

      <Footer />
    </>
  )
}
